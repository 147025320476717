import { useContext, useEffect, useRef, useState } from 'react'
import { graphql, navigate, PageProps } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { noScrollbars } from '../theme/theme'
import { Arrow } from '../components/icons/Arrow'
import { ButtonShadow } from '../components/buttons/ButtonShadow'
import { ButtonShadowIcon } from '../components/buttons/ButtonShadowIcon'
import { DetectiveWallBoard } from '../components/DetectiveWallBoardBoard'
import { DetectiveWallPageHeader } from '../components/pageHeaders/DetectiveWallPageHeader'
import { Flex } from '../components/Flex'
import { Page } from '../components/Page'
import { AppContext } from '../contexts/AppContext'
import { IGatsbyImages, IcebergLevels } from '../@types/mememanifesto'

const PageWrapper = styled.div`
  width: 100%;
  height: var(--vh);
  background: rgba(41, 41, 41, 1);
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  width: 100%;
  height: calc(var(--vh) - ${({ theme }) => theme.menuHeight * 2}px);
  display: flex;
  justify-content: center;

  ${props => props.theme.mediaQuery('desktop')} {
    height: calc(var(--vh) - 180px);
  }
`

const BoardsWrapper = styled.div`
  ${noScrollbars}
  width: 100vw;
  padding: 0 15vw;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  overflow-x: auto;

  ${props => props.theme.mediaQuery('tablet')} {
    padding: 0 33.333vw;
  }

  ${props => props.theme.mediaQuery('desktop')} {
    width: 70vw;
    max-width: 1250px;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr;
    gap: 10px;
  }
`

const BoardS = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 70vw;
  margin: 0 2vw;

  ${props => props.theme.mediaQuery('tablet')} {
    flex-basis: 30vw;
  }

  ${props => props.theme.mediaQuery('desktop')} {
    flex-basis: 100vw;
    margin: 0;
  }
`

const TabletFooter = styled(Flex)`
  padding: 0 32px;
  flex: 0 0 ${({ theme }) => theme.menuHeight}px;
`

const DetectiveWall: React.FC<
  PageProps<
    IGatsbyImages<{
      name: IcebergLevels
    }>,
    undefined,
    { level?: IcebergLevels }
  >
> = ({ location, data }) => {
  const [currentBoard, setCurrentBoard] = useState(location.state?.level ?? '1')
  const board = (level: IcebergLevels) => {
    const image = data.allFile.edges.find(
      edge => edge.node.name === level
    )?.node
    if (!image) {
      throw new Error(`Image ${level} not found`)
    }
    return {
      level,
      full: getImage(image),
    }
  }

  const { isTablet } = useContext(AppContext)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const ref1 = useRef<HTMLDivElement>(null)
  const ref2 = useRef<HTMLDivElement>(null)
  const ref3 = useRef<HTMLDivElement>(null)
  const ref4 = useRef<HTMLDivElement>(null)
  const ref5 = useRef<HTMLDivElement>(null)
  const ref6 = useRef<HTMLDivElement>(null)
  const ref7 = useRef<HTMLDivElement>(null)
  const ref8 = useRef<HTMLDivElement>(null)
  const ref9 = useRef<HTMLDivElement>(null)
  const ref10 = useRef<HTMLDivElement>(null)

  const scrollToBoard = (newBoard: IcebergLevels) => {
    const ref = [ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10][
      parseInt(newBoard) - 1
    ]
    if (ref.current && wrapperRef.current) {
      ref.current.scrollIntoView({
        inline: 'center',
        behavior: 'smooth',
      })
    }
  }

  const goToPrevBoard = () => {
    const prevBoard =
      currentBoard === '1'
        ? '10'
        : ((parseInt(currentBoard) - 1).toString() as IcebergLevels)
    scrollToBoard(prevBoard)
    setCurrentBoard(prevBoard)
  }

  const goToNextBoard = () => {
    const nextBoard =
      currentBoard === '10'
        ? '1'
        : ((parseInt(currentBoard) + 1).toString() as IcebergLevels)
    scrollToBoard(nextBoard)
    setCurrentBoard(nextBoard)
  }

  useEffect(() => {
    const listener = () => {
      scrollToBoard(currentBoard)
    }
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [currentBoard])

  useEffect(() => {
    const el = wrapperRef.current
    const listener = () => {
      if (el) {
        setCurrentBoard(
          (
            Math.floor(
              // + 1 è un offset per far funzionare l'ultima board
              ((el.scrollLeft + 1) /
                (el.scrollWidth -
                  parseInt(
                    getComputedStyle(el).getPropertyValue('padding-left')
                  ) *
                    2)) *
                10
            ) + 1
          ).toString() as IcebergLevels
        )
      }
    }
    el?.addEventListener('scroll', listener)
    return () => {
      el?.addEventListener('scroll', listener)
    }
  }, [])

  return (
    <Page pageTitle="Detective Wall" pathname={location.pathname}>
      <PageWrapper>
        <DetectiveWallPageHeader />
        <Wrapper>
          <BoardsWrapper ref={wrapperRef}>
            <BoardS ref={ref1}>
              <DetectiveWallBoard board={board('1')} />
            </BoardS>
            <BoardS ref={ref2}>
              <DetectiveWallBoard board={board('2')} />
            </BoardS>
            <BoardS ref={ref3}>
              <DetectiveWallBoard board={board('3')} />
            </BoardS>
            <BoardS ref={ref4}>
              <DetectiveWallBoard board={board('4')} />
            </BoardS>
            <BoardS ref={ref5}>
              <DetectiveWallBoard board={board('5')} />
            </BoardS>
            <BoardS ref={ref6}>
              <DetectiveWallBoard board={board('6')} />
            </BoardS>
            <BoardS ref={ref7}>
              <DetectiveWallBoard board={board('7')} />
            </BoardS>
            <BoardS ref={ref8}>
              <DetectiveWallBoard board={board('8')} />
            </BoardS>
            <BoardS ref={ref9}>
              <DetectiveWallBoard board={board('9')} />
            </BoardS>
            <BoardS ref={ref10}>
              <DetectiveWallBoard board={board('10')} />
            </BoardS>
          </BoardsWrapper>
        </Wrapper>
        {isTablet && (
          <TabletFooter justify="space-between" align="center" fillParent>
            <ButtonShadowIcon onClick={goToPrevBoard}>
              <Arrow direction="left" />
            </ButtonShadowIcon>
            <ButtonShadow
              onClick={() =>
                navigate(`/level-${currentBoard}?call-of-the-memesphere`)
              }
            >
              Discover Level {currentBoard}
            </ButtonShadow>
            <ButtonShadowIcon onClick={goToNextBoard}>
              <Arrow direction="right" />
            </ButtonShadowIcon>
          </TabletFooter>
        )}
      </PageWrapper>
    </Page>
  )
}

export default DetectiveWall

export const pageQuery = graphql`
  query {
    allFile(
      filter: {
        sourceInstanceName: { eq: "boards" }
        relativePath: { glob: "full/*" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 700, height: 1000)
          }
          name
        }
      }
    }
  }
`
