import styled from 'styled-components'
import { Flex } from './Flex'
import { Board as BoardType } from '../@types/mememanifesto'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { ButtonShadowIcon } from './buttons/ButtonShadowIcon'
import { Lens } from './icons/Lens'

const LinkS = styled(Link)`
  position: relative;
  max-height: 100%;
`

const ButtonShadowIconS = styled(ButtonShadowIcon)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: auto;
`

const Wrapper = styled(Flex)`
  justify-content: center;
  ${props => props.theme.mediaQuery('desktop')} {
    justify-content: start;
    height: 100%;
  }
`

interface Props {
  className?: string
  board: BoardType
}

export const DetectiveWallBoard: React.FC<Props> = ({ className, board }) => {
  return (
    <Wrapper className={className} column>
      <LinkS to={`/level-${board.level}?call-of-the-memesphere`}>
        <>
          <ButtonShadowIconS shadow="intense">
            <Lens />
          </ButtonShadowIconS>
          {board.full && (
            <GatsbyImage
              image={board.full}
              alt={`Board ${board.level}`}
              objectFit="contain"
              style={{ width: '100%', height: '100%' }}
            />
          )}
        </>
      </LinkS>
    </Wrapper>
  )
}
