import { Link } from 'gatsby'
import { Flex } from '../Flex'
import { ButtonShadowIcon } from './ButtonShadowIcon'
import styled from 'styled-components'
import { fontHaasGrot } from '../../theme/theme'

const Button = styled.div`
  ${fontHaasGrot}
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
`

const LinkS = styled(Link)`
  text-decoration: none;
  display: block;
`

const LinkText = styled.div<{ side: 'left' | 'right' }>`
  ${({ side }) =>
    side === 'left'
      ? 'margin-left: 20px;'
      : 'margin-right: 20px; text-align: right;'}
`

interface PropsCommon {
  side: 'left' | 'right'
  text: string
  icon: React.ReactNode
  variant?: 'tall' | 'wide'
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  className?: string
  style?: React.CSSProperties
}

interface PropsClick extends PropsCommon {
  onClick: React.MouseEventHandler
}

interface PropsLink extends PropsCommon {
  link: string
  state?: object
}

export const ButtonShadowIconText: React.FC<PropsClick | PropsLink> = ({
  text,
  icon,
  side,
  variant,
  onMouseEnter,
  onMouseLeave,
  className,
  style,
  ...props
}) => {
  const ButtonContent = (
    <Flex
      align="center"
      reverse={side === 'left'}
      justify={side === 'left' ? 'start' : 'end'}
    >
      {text && <LinkText side={side}>{text}</LinkText>}
      <ButtonShadowIcon variant={variant}>{icon}</ButtonShadowIcon>
    </Flex>
  )

  if ('link' in props) {
    return (
      <LinkS
        to={props.link}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        style={style}
        state={props.state}
      >
        <Button>{ButtonContent}</Button>
      </LinkS>
    )
  } else {
    return (
      <Button
        onClick={props.onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        role="button"
        tabIndex={0}
        className={className}
        style={style}
      >
        {ButtonContent}
      </Button>
    )
  }
}
