import styled from 'styled-components'
import { Pill } from './Pill'

export const ButtonIcon = styled(Pill)`
  width: 60px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
`
