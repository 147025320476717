import { useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import styled from 'styled-components'

import { PageHeader } from './PageHeader'
import { ButtonShadowIconText } from '../buttons/ButtonShadowIconText'
import { Cross } from '../icons/Cross'
import { Iceberg } from '../icons/Iceberg'
import { Menu } from '../icons/Menu'
import {
  fontHaasGrot,
  fontSneaky,
  hideOnDesktop,
  hideOnTablet,
} from '../../theme/theme'
import { ButtonShadowIcon } from '../buttons/ButtonShadowIcon'
import { PillShadow } from '../buttons/PillShadow'
import { ButtonIcon } from '../buttons/ButtonIcon'
import { Flex } from '../Flex'
import { Detective } from '../icons/Detective'

const Title = styled.div`
  ${hideOnTablet}
  ${fontSneaky}
  font-style: italic;
`

const InfoText = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.color('topTextColor')};
  background-color: ${({ theme }) => theme.color('backgroundColor')};
  border-radius: 21px;
  width: auto;
  top: 110px;
  height: calc(var(--vh) - 140px);
  right: 30px;
  z-index: 3;
  padding: 45px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-width: 620px;
  margin-left: 30px;

  ${props => props.theme.mediaQuery('desktop')} {
    top: 65px;
    height: calc(var(--vh) - 115px);
    right: 140px;
  }
`

const InfoTextScroll = styled.div`
  overflow: auto;
  flex: 1;
  ${fontHaasGrot}
`

const ButtonInfotextDesktop = styled(ButtonShadowIconText)`
  ${hideOnTablet}
  z-index: 2;
`

const InfoBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  background-color: rgba(41, 41, 41, 0.5);
`

const InfoTextTitle = styled.h2`
  font-size: clamp(1.3rem, 5vw, 2.6rem);
`

const HomeButtonMobile = styled(ButtonShadowIcon)`
  ${hideOnDesktop}
`

const HomeButtonDesktop = styled(ButtonShadowIconText)`
  ${hideOnTablet}
`

const ButtonInfotextTablet = styled(PillShadow)`
  ${hideOnDesktop}
  z-index: 2;
`

export const DetectiveWallPageHeader: React.FC = () => {
  const location = useLocation()
  const [viewInfoText, setViewInfoText] = useState<boolean>(
    new URLSearchParams(location.search).get('call-of-the-memesphere') !== null
  )
  return (
    <>
      {viewInfoText && (
        <InfoBackground onClick={() => setViewInfoText(false)} />
      )}
      <PageHeader>
        <HomeButtonMobile onClick={() => navigate(`/`)}>
          <Iceberg />
        </HomeButtonMobile>
        <HomeButtonDesktop
          side="left"
          text="Go To The Iceberg"
          icon={<Iceberg />}
          link="/"
        />
        <Title>Discover more about the 10 different memes</Title>
        <ButtonInfotextTablet onClick={() => setViewInfoText(!viewInfoText)}>
          <Flex>
            {viewInfoText && (
              <ButtonIcon>
                <Cross />
              </ButtonIcon>
            )}
            {!viewInfoText && (
              <>
                <ButtonIcon>
                  <Detective />
                </ButtonIcon>
                <ButtonIcon>
                  <Menu />
                </ButtonIcon>
              </>
            )}
          </Flex>
        </ButtonInfotextTablet>
        <ButtonInfotextDesktop
          side="right"
          text="Read about The Detective Wall"
          icon={viewInfoText ? <Cross /> : <Menu />}
          onClick={() => setViewInfoText(!viewInfoText)}
        />
      </PageHeader>
      {viewInfoText && (
        <InfoText>
          <InfoTextTitle>THE CALL OF THE MEMESPHERE</InfoTextTitle>
          <InfoTextScroll>
            <p>When going down to Hell, there’s always a need for a Guide.</p>
            <p>
              Hence here we are, offering you our left hand and welcoming you to
              scan the wall (or explore the web-pages) where we collected
              countless little fragments from the Internet’s subconscious: the
              irrefutable evidence of our passage through the unfathomable seas
              of the memesphere.
            </p>
            <p>
              When, in the early months of 2018, we embarked on a mission to
              show the potential for political activism of memes, we had no idea
              of what we were doing. Or, to be more accurate, no idea of what we
              were <em>truly</em> doing. A classic tale of esoteric
              power-evocation gone wrong, if you want: a postmodern version of
              the sorcerer's apprentice. Had we known what we were about to
              embark on, surely we wouldn’t have done it - what person with a
              sane mind would have?
            </p>
            <p>
              So here we are, trying to exorcise our poor, tired spirits from
              all the memes we had to watch in the meantime. But they never
              stop. They keep coming. Every day. Even now, as we are writing
              these words. And nevertheless, we can’t help it. The work must be
              done.
            </p>
            <p>
              How to map the unmappable? How to represent an object that, by its
              nature, defies representation (which is to say, any living or
              “real” object)?
            </p>
            <p>
              Every archive ever conceived is a desperate attempt to counter the
              universal laws of thermodynamics. Fear of entropy besets the
              silent shelfs of dusty libraries.
            </p>
            <p>
              Few undertakings speak to this truth as much as humanity’s
              repeated attempts to map the ever changing landscape of the
              digital world we call the Internet. And probably no other
              continent in this nightmarish, kaleidoscopic cyberspace is as
              difficult and ultimately impossible to map and represent as the
              one we have come to know as the memesphere.
            </p>
            <p>
              And yet, we delude ourselves that we have a minimum knowledge of
              this great universe and would like to pass it on somehow. We have
              asked ourselves: is it possible to state something like a set of
              general rules for the memesphere? A list of instructions and
              warnings, maybe; an elaborated and savvy, but ultimately equally
              nonsensical version of what the “Rules of the Internet” once were?
              For starters, we could compile long lists of categories: poorly
              made memes,{' '}
              <span style={{ fontSize: '90%' }}>original content memes</span>,{' '}
              <span style={{ fontSize: '80%' }}>surreal memes</span>,{' '}
              <span style={{ fontSize: '70%' }}>fried memes</span>,{' '}
              <span style={{ fontSize: '60%' }}>nuked memes</span>. We could
              drive ourselves crazy by trying to juxtapose memes following
              recurring themes: the wholesome,{' '}
              <span style={{ fontSize: '90%' }}>the ugly</span>,{' '}
              <span style={{ fontSize: '80%' }}>the absurd</span>,{' '}
              <span style={{ fontSize: '70%' }}>the edgy</span>,{' '}
              <span style={{ fontSize: '50%' }}>the weird</span>,{' '}
              <span style={{ fontSize: '60%' }}>the cursed</span>. We may spend
              years of our time, including video call discussions, workshops
              around the world and 24/7 collective chats, wondering why memes
              have been shown to have hyperstitional power, convincing “people
              to do things” or at least spreading complex ideological or
              commercial messages at <em>g r e a t s p e e d</em>.
            </p>
            <p>
              Ultimately, in a random, crazy, catastrophic collective gesture,
              we decided to do all of these things at the same time.
            </p>
            <p>
              While our work may have a semblance of order and meaning on its
              surface, we warn you not to be fooled: this is just a reassuring
              mask we wear, in order to lure you close enough to the edge of the
              abyss. The moment you lean out and peek into the endless darkness
              below, you will feel our hand on your back, gently pushing you
              over the edge. In our hearts, we hope that you will be able to
              draw some lessons from this tentative ritual sacrifice of ours
              (spoiler: you are the goat).
            </p>
            <p>
              Starting from the assumption that representing the memesphere as a
              whole is impossible, what we have set ourselves to do is an
              interpretation of what we have seen and lived throughout the past
              years: an attempt to build a limited set of narratives from our
              consciously circumscribed and subjective point of view. We
              collected tales about how a given user, or multiple users,
              experienced a phenomenon, an aesthetic trend, a recurring semantic
              strategy; and we tried to sketch and represent some brief, but
              arguably highly influential moments in the history of memetics. We
              started from the anecdotes, niches and areas of the internet that
              we knew from our personal experience. Like little beavers who, by
              joining together, try to build a dam of logs, in a desperate
              attempt to contain the endless flood of content being created on
              the net day after day.
            </p>
            <p>
              The first thing we realized was that, to tell a story about a
              meme, a single image would never suffice. The Meme is always
              plural, never singular. It is perceived in a context, it is
              enjoyed in clusters; its very existence is by definition only
              possible as a variant of other memes and as a result of an
              interaction - be it only between a bunch of users. To make this
              clear from the beginning: virality is not a necessary precondition
              for a meme to be recognized as such. If I share a meme I made with
              a friend - and only with him - doesn’t it already count as a meme?
            </p>
            <p>
              Of course we know that this is still an open debate. To this day,
              meme studies are struggling to find a universally accepted
              definition of meme. While British ethnologist Richard Dawkins
              seminal definition of the meme as a “unit of significance” heavily
              influenced the field of memetic studies, Israley scholar Limor
              Shifmann later introduced the concept of memes as collective
              rituals, and Seong-Young Her, cofounder of the popular meme
              studies blog The Philosopher Meme, prefers to talk about
              “lineages”. As humble researchers and craftspeople, we decided to
              go for a more nonsensical and aesthetic approach. In this, the
              spirit of the great Aby Warburg came to our aid.
            </p>
            <p>
              Warburg was not just one of the first, together with Benjamin, to
              recognize the revolutionary impact photography would have on
              modern society. He also developed an interdisciplinary approach to
              the study of visual culture that resonates to this day. His
              <em>Bilderatlas Mnemosyne</em> has set a benchmark by representing
              at the same time a method, a research archive and a unique work of
              art. Together with the so-called crazy wall, popularized by
              countless detective movies and gifted to us by the demons of meme
              magick in the form of the “Pepe Silvia” meme, the Bilderatlas has
              been an irreplaceable model for the construction of the Detective
              Wall – that is, the ten panels you are about to explore.
            </p>
            <p>
              Throughout this experience, the users, that is, you and your
              multiple dormant personalities, will be led through ever deeper
              levels of memetics, from the most popular, well-known formats to
              the most occult and esoteric. Below, you can find a synthetic
              overview of what to expect.
            </p>
            <p>Each character is a totem.</p>
            <p>Each totem corresponds to a panel.</p>
            <p>
              Each panel is a level in your journey towards the complete loss of
              meaning.
            </p>
            <p>
              <strong>[Insert Trollface]</strong> Our journey starts in the
              2000s, when social media didn’t exist and social networks were
              happening in email threads, IRC chats, or forums like Something
              Awful and 4chan. In this panel we investigate the emergence of
              meme making as a collective ritual, exploring a time when
              communities of self-aware trolls organized legendary memetic
              invasions and epic DDoS (distributed denial-of-service) attacks.
              In the process, they created the rules of conduct that would come
              to influence the IRL demonstrations and operations of the rising
              Anonymous movement, while also laying the foundation for many
              subsequent memetic practices.
            </p>
            <p>
              <strong>[Insert Doge]</strong> The early 2010s were the time when
              memes spread across mainstream social media, colonizing offices,
              morphing into self-made t-shirts, and finally seeping into
              advertising. These were also the first years in which memetic
              communities tried to understand how to defend themselves, perhaps
              in vain, from the incessant drift towards normalization, while
              also asserting their role as one of the web’s most influential
              subcultures. Maybe most importantly, this was the moment when the
              classic “advice animals” format entered the shared imaginary, even
              gaining a Wikipedia entry and arguably becoming the meme format
              par excellence.
            </p>
            <p>
              <strong>[Insert Harold]</strong> Trying to evade the coercion to
              normalization imposed by the totalitarian design of Mark
              Zuckerberg’s (only called “the Zucc” by his many detractors)
              corporate platforms, memetic communities started manifesting their
              most intimate fears, edgy thoughts and weird desires in a way that
              was definitely not conforming to official “community policies”.
              The outcome of this epic fight between a rat pack of misfits and
              the evil overlord of Social Media has been twofold: resulting on
              one side in the creation of the “relatable meme” genre, and on the
              other contributing to reclaim a small, but consistent section of
              Facebook through an archipelago of pages, private groups and rogue
              profiles, which came to be known as the “Weird Facebook”.
            </p>
            <p>
              <strong>[Insert Pepe]</strong> Reaction characters like Wojak,
              Pepe, Spurdo Sparde: they've always had a story of their own, and
              what a story! Their power to concentrate complex meanings in a
              simple figure has led them to become like magical totems, digital
              archetypes of sorts, the powers of which we are channeling in this
              book. They are our guides, our lights in the night. This
              incredible apex of semiotic power has led them to become potent
              means of propaganda: from Pepe the Frog, reincarnation of the
              ancient Egyptian god Kek and tutelary deity of the alt-right, to
              Gritty, king of chaos and protector of the far left.
            </p>
            <p>
              <strong>[Insert Chad]</strong> Memes are often representative of
              ourselves, our fears and our way of relating to society. The
              “Oomers”, generational and cultural allegories projected on the
              meme character originally known as Wojak, speak to us of
              contemporary identity conflicts especially (but not only) around
              issues like gender, social class, politics and race. Memes with a
              strong dichotomic vision, like the so-called “Virgin vs. Chad”
              format, want to force us into a universe of winners and losers.
              Luckily, this type of vision can be subverted and reclaimed for
              the benefit of all, including discriminated minorities.
            </p>
            <p>
              <strong>[Insert Dat Boi]</strong> During our path, we also had to
              ask ourselves: can so-called OC (Original Content) images
              legitimately be considered memes? Our answer is yes. Most of these
              images are created by a community of meme artists who frequently
              operate on Instagram, originally coming from Weird Facebook (from
              2016 onwards) and Tumblr. Observing them brought us to reflect on
              past diasporas, like the user migration from Something Awful to
              Weird Twitter, or from 4chan and Tumblr to Weird Facebook, and
              about how common memetic practices and stylistic hallmarks are
              often used by members of deplatformed communities to recognize and
              find each other during the transition from one social media
              platform to another. We recognized similar patterns and recurrent
              stylistic elements in the OC community, including the vaporwave
              aesthetic, the use of brandalism, and frequent references to
              mental-health related topics - but also a very self-conscious and
              fluid relationship with sexuality and gender issues.
            </p>
            <p>
              <strong>[Insert Clippy]</strong> There was a point in meme history
              when things got heavily mannerist and Meta-Memes happened. This
              type of tendency, already present in self-reflective versions of
              memes belonging to the era of advice animals such as “Yo Dawg” or
              “You're Eating Recursion! Sweet Jesus, Pooh!”, drastically
              intensified between 2015 and 2017, a time characterized by an
              explosion of new formats in the Dank Weird Facebook community.
              This was the moment in which classical yes/no meme formats like
              “Drakeposting” or “Left Exit 12 Off Ramp” quickly slid towards
              empty episodes of self-reflexivity - but it was also the moment in
              which memeculture became increasingly self-aware and theoretically
              sophisticated.
            </p>
            <p>
              <strong>[Insert NPC]</strong> The creative writing and esoteric
              aesthetics of conspiracy theories have always fascinated users of
              4chan, reddit or YouTube. Nothing like the thrill of coming into
              contact with anonymous whistleblowers or powerful masterminds,
              hiding behind their screens and blabbing out their most nefarious
              secrets. Unfortunately, like everything else, this fascination can
              also be hijacked by think-thanks and politicians with their own
              political agenda. That is why, at a certain point of our path, we
              felt that we had to go deeper down the pizzagate and Qanon hole,
              trying to find out why these narratives have so much hold on the
              most unexpected users, from Psytrance neo-hippies and former
              fashion designers to concerned moms and yoga teachers.
            </p>
            <p>
              <strong>[Insert Meme Man]</strong> In the Surreal Meme Universe,
              the reaction characters’ saga transformed into a true narrative
              multiverse with its own set of laws and legends. Over the years,
              the adventures of Meme Men, Angry Orange, and all the other canon
              characters created in this unique experiment of collective,
              participative storytelling, have become so intricate that users
              had to build a very detailed fan wiki on Fandom to keep track.
              There, you will find the canon characters’ "Scale of
              Trustworthiness", you will learn about the attempts to unleash the
              seal on the "C O L o u r e s", read about "Void City", and witness
              the senseless and never ending quests, battles and deaths of Meme
              Man and Angry Orange, as they have been narrated through
              user-generated content hosted mainly on 4chan, YouTube and reddit.
            </p>
            <p>
              <strong>[Insert Crying Laughing Emoji]</strong> With every further
              transmission, digital information is inevitably subject to
              deterioration. This well-known phenomenon, called “generation
              loss”, has inspired the highly artful and avant-garde memetic
              practice known as “deep frying”, which consists in reposting a
              meme to the point where it becomes unrecognizable. However, the
              images shown here are “poor” not only in the quality of their
              resolution, but also, as US writer Aria Dean reminds us, because
              they are inherently rooted in black American culture – and as
              such, in a history of oppression, exploitation and cultural
              appropriation by the ruling white majority. To fully recognize the
              crucial contribution of communities like the so-called “Black
              Twitter” to the rise of the memesphere, we need to break up the
              singular, white and anglophile gaze on memetics, and open it up to
              a plurality of perspectives. So while this is, in a certain sense,
              the final panel of the Detective Wall, it actually is also the
              first: the point where all information is lost, and thus
              narratives can be re-defined, re-opened and re-invented.
            </p>
          </InfoTextScroll>
        </InfoText>
      )}
    </>
  )
}
