import arrowUp from '../../assets/icons/arrowUp.svg'
import arrowRight from '../../assets/icons/arrowRight.svg'
import arrowDown from '../../assets/icons/arrowDown.svg'
import arrowLeft from '../../assets/icons/arrowLeft.svg'
import { Icon } from './Icon'

interface Props {
  direction: 'up' | 'right' | 'down' | 'left'
  size?: string | [string, string]
  style?: React.CSSProperties
}

const ArrowUp = Icon(arrowUp)
const ArrowRight = Icon(arrowRight)
const ArrowDown = Icon(arrowDown)
const ArrowLeft = Icon(arrowLeft)

export const Arrow: React.FC<Props> = ({ direction, size, style }) => {
  switch (direction) {
    case 'up':
      return <ArrowUp size={size} style={style} />
    case 'right':
      return <ArrowRight size={size} style={style} />
    case 'down':
      return <ArrowDown size={size} style={style} />
    case 'left':
      return <ArrowLeft size={size} style={style} />
  }
}
