import styled from 'styled-components'
import { Flex } from '../Flex'

const Header = styled(Flex)`
  height: 110px;
  align-items: center;
  margin: 0 32px;
  flex-shrink: 0;

  ${props => props.theme.mediaQuery('desktop')} {
    height: 180px;
    margin: 0 70px;
  }
`

interface Props {
  children: React.ReactNode
}

export const PageHeader: React.FC<Props> = ({ children }) => {
  return <Header justify="space-between">{children}</Header>
}
